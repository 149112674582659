import React, { useEffect, useState } from 'react'
import './home.css'
import HomePic from '../../Images/dentalPic/dental.jpg'
import arrow from '../../Images/Icons/rightArrow.png'
import quality from '../../Images/Icons/gear.png'
import price from '../../Images/Icons/price.png'
import care from '../../Images/Icons/care.png'
import shield from '../../Images/Icons/shield.png'
import Specialities from '../Specialities/Specialities'
import Care from '../Care/Care'
import Testimonial from '../Testimonial/Testimonial'
import EnquiryForm from '../floatingMenu/EnquiryForm'

function Home() {
  const [isEnquiry, setIsEnquiry] = useState(false);
  useEffect(()=>{
    window.scroll(0, 0);
  })
  return (
    <>
      <div className="container-fluid home-content-bg">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-12 col-lg-6 col-xxl-6">
              <div className="home-left-content">
                <h5 className="home-wel-head">
                  Welcome to <span className="dental-font">Neosmile</span>{" "}
                  Dental
                </h5>
                <h3 className="home-wel-main-head">
                  Best <span className="dental-font">Dental Care</span> In
                  Chennai
                </h3>
                <p className="home-wel-content">
                  At Neosmile, we leverage the expertise of top dental
                  professionals and the latest technological advancements to
                  ensure exceptional dental care for our patients.
                </p>
                <p className="home-wel-content">
                  {" "}
                  our goal is to inspire confidence, happiness, and optimism in
                  our patients. Healthy teeth, healthy life
                </p>
                <div className="home-btn">
                  <button
                    className="apply-button"
                    onClick={() => setIsEnquiry(!isEnquiry)}
                  >
                    Book Appointment <img src={arrow} className="home-arrow" />{" "}
                  </button>
                </div>
              </div>
            </div>
            <div className="col-sm-12 col-md-12 col-lg-6 col-xxl-6">
              <div className="home-right-content">
                <div className="home-img-content">
                  <img src={HomePic} className="home-dental-pic" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container bg-y-neo">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4 col-xxl-4">
            <div className="neo-y-left">
              <h3 className="text-center neo-y-head">
                Why <span className="dental-font">Neosmile</span> for you?
              </h3>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-8 col-xxl-8">
            <div className="row justify-content-center align-items-center">
              <div className="col-sm-12 col-md-12 col-lg-6 col-xxl-6 zig-zag">
                <div class="card neo-card">
                  <div class="card-body">
                    <img src={quality} className="py-2" />
                    <h5 class="card-title neo-card-title">
                      High Quality Equipments
                    </h5>
                    <p class="card-text neo-card-text">
                      NeoSmile Dental is equipped with cutting-edge technology,
                      ensuring that our patients receive the highest standard of
                      care. Our modern equipment guarantees precision and
                      accuracy in all our dental procedures.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 col-xxl-6">
                <div class="card neo-card">
                  <div class="card-body">
                    <img src={care} className="py-2" />
                    <h5 class="card-title neo-card-title">
                      Comprehensive Dental Services
                    </h5>
                    <p class="card-text neo-card-text">
                      At NeoSmile Dental in Chennai, our team of specialists
                      from various dental disciplines is committed to addressing
                      the unique needs of each patient.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 col-xxl-6 zig-zag">
                <div class="card neo-card">
                  <div class="card-body">
                    <img src={price} className="py-2" />
                    <h5 class="card-title neo-card-title">
                      Affordable Pricing
                    </h5>
                    <p class="card-text neo-card-text">
                      We are dedicated to providing the highest quality
                      treatment and dental care for both adults and children,
                      all at affordable prices.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-12 col-lg-6 col-xxl-6">
                <div class="card neo-card">
                  <div class="card-body">
                    <img src={shield} className="py-2" />
                    <h5 class="card-title neo-card-title">
                      Transform Your Smile Today!
                    </h5>
                    <p class="card-text neo-card-text">
                      Experience the convenience and effectiveness of our
                      Invisalign aligners for a healthier, straighter smile.
                      Take the first step toward a more confident you by booking
                      your appointment today!
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Specialities />
      <Care />
      <Testimonial />
      <EnquiryForm isEnquiry={isEnquiry} setIsEnquiry={setIsEnquiry} />
    </>
  );
}

export default Home