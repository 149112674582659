import React, { useState } from "react";
import "./floating.css";

import enroll from "../../Images/Icons/appointment.png";
import email from "../../Images/Icons/email.png";
import linkedin from "../../Images/Icons/linkedin.png";
import whatsapp from "../../Images/Icons/whatsapp.png";
import { Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";
import Select from "react-select";
import EnquiryForm from "./EnquiryForm";

function Floating() {
  const [isEnquiry, setIsEnquiry] = useState(false);
  return (
    <>
      <div className="floating-menu">
        <a
          className="fl-item edge-t fl-contact"
          onClick={() => setIsEnquiry(!isEnquiry)}
        >
          <div class="icon">
            <img src={enroll} className="img-rotate" />
          </div>
          <span class="text">Book</span>
        </a>
        <a className="fl-item fl-email" href="mailto:neosmiledentalomr@gmail.com">
          <div class="icon">
            <img src={email} className="img-rotate" />
          </div>
          <span class="text">Email</span>
        </a>
        <a
          className="fl-item edge fl-wa"
          target="_blank"
          href="https://api.whatsapp.com/send?phone=7200943938&text=Hello%2C%20I%20Need%20a%20Appointment%20.%2C%20Please%20Contact%20me"
        >
          <div class="icon">
            <img src={whatsapp} className="img-rotate" />
          </div>
          <span class="text">Whatsapp</span>
        </a>
      </div>
      <EnquiryForm isEnquiry={isEnquiry} setIsEnquiry={setIsEnquiry} />
    </>
  );
}

export default Floating;
