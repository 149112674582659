import React, { useEffect, useState } from "react";
import "./navbar.css";
import logo from '../../Images/Logo/neosmile_bg.png'
import { AnimatePresence, motion } from "framer-motion";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Accordion from "../Animations/Accordion";
import EnquiryForm from "../floatingMenu/EnquiryForm";

export const treatmentList = [
  {
    name: "Aligners",
    link:'aligners'
  },
  {
    name: "Dental Implant",
     link:'dental-implants'
  },
  {
    name: "Root Canal Treatment",
     link:'root-canal-treatment'
  },
  {
    name: "Teeth-Whitening",
    link:'teeth-whitening'
  },
  {
    name: "Family Dentistry",
     link:'family-dentistry'
  },
  {
    name: "Resin Bonded",
    link: "resin-bonded",
  },
  {
    name: "Sleep Dentistry",
     link:'sleep-dentistry'
  },
  {
    name: "Cosmetic Dentistry",
     link:'cosmetic-dentistry'
  },
  {
    name: "Gum Contouring & Lifting",
     link:'Gum-Contouring-and-Lifting'
  },
  {
    name: "Porcelain veneers",
     link:'porcelain-veneers'
  },
  {
    name: "Gum Grafting",
     link:'gum-grafting'
  },
];
function Navbar() {
  const navigate = useNavigate();
  const location = useLocation().pathname;
  console.log(location);
  const params = useParams();
  const [isEnquiry, setIsEnquiry] = useState(false);
  const [isMouse, toggleMouse] = useState(false);
  const [activeMenu, setActiveMenu] = useState("Home");

  const [isOpen, setIsOpen] = useState(false);
  const [isSubMenu, setIsSubMenu] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  const toggleMouseMenu = () => {
    toggleMouse(!isMouse);
  };

  const subMenuAnimate = {
    enter: {
      opacity: 1,
      rotateX: 0,
      transition: {
        duration: 0.5,
      },
      display: "block",
      cursor: "pointer",
    },
    exit: {
      opacity: 0,
      rotateX: -15,
      transition: {
        duration: 0.5,
        delay: 0.3,
      },
      transitionEnd: {
        display: "none",
      },
    },
  };

  const subMenuItemAnimate = {
    enter: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.3,
      },
      zIndex: 1000,
    },
    exit: {
      opacity: 0,
      y: -20,
      transition: {
        duration: 0.3,
      },
    },
  };
  return (
    <div className="container-fluid navbar-sticky-top bg-white p-0">
      <div className="container navbar-sticky-top p-0">
        <nav className="navbars">
          <div className="navbar-logo">
            <img src={logo} alt="Logo" onClick={() => navigate("/")} />
          </div>

          <div className={`hamburger ${isOpen ? "open" : ""}`} onClick={toggleMenu}>
            <div className="bar bar1"></div>
            <div className="bar bar2"></div>
            <div className="bar bar3"></div>
          </div>
          <motion.nav
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: isOpen ? 1 : 0, y: isOpen ? 0 : -20 }}
            transition={{ duration: 0.5 }}
            className={`menu ${isOpen ? "open" : ""}`}
          >
            <ul>
              <li  onClick={()=>{
                          navigate(`/`);
                          toggleMenu();
                          }}>
                <a>Home</a>
              </li>
              <li  onClick={()=>{
                          navigate(`/about`);
                          toggleMenu();
                          }}>
                <a >About Us</a>
              </li>
              <li>
                <Accordion title={"Treatments"}>
                  <div>
                    {
                      treatmentList.map((list)=>{
                        return <li onClick={()=>{
                          navigate(`/treatments/${list.link}`);
                          toggleMenu();
                          }}>
                        <a>{list.name}</a>
                      </li>
                      }) 
                    }
                  </div>
                </Accordion>
              </li>
              <li  onClick={()=>{
                          navigate(`/blog`);
                          toggleMenu();
                          }}>
                <a>Blog</a>
              </li>
              <li  onClick={()=>{
                          navigate(`/contact`);
                          toggleMenu();
                          }}>
                <a>Contact Us</a>
              </li>
            </ul>
          </motion.nav>
          <div className="navbar-links">
            <a
              className={
                location === "/" ? "navbar-link active" : "navbar-link"
              }
              onClick={() => {
                navigate("/");
                setActiveMenu("Home");
              }}
            >
              Home
            </a>
            <a
              className={
                location.split("/")[1] === "about"
                  ? "navbar-link active"
                  : "navbar-link"
              }
              onClick={() => {
                navigate("/about");
                setActiveMenu("about");
              }}
            >
              About Us
            </a>
            <div className="menu-item">
              <a
                className={
                  location.split("/")[1] === "treatments"
                    ? "navbar-link active"
                    : "navbar-link"
                }
              >
                Treatments
              </a>
              <div className="sub-menu">
                <div className="sub-menu-background" />
                <div className="sub-menu-container">
                  {treatmentList.map((item, index) => (
                    <div
                      className="sub-menu-item"
                      key={index}
                      onClick={() => {
                        navigate(`/treatments/${item.link}`);
                        setActiveMenu("treatments");
                      }}
                    >
                      {item.name}
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <a
              className={
                location.split("/")[1] === "blog"
                  ? "navbar-link active"
                  : "navbar-link"
              }
              onClick={() => {
                navigate("/blog");
                setActiveMenu("blog");
              }}
            >
              Blog
            </a>
            <a
              className={
                location.split("/")[1] === "contact"
                  ? "navbar-link active"
                  : "navbar-link"
              }
              onClick={() => {
                navigate("/contact");
                setActiveMenu("contact");
              }}
            >
              Contact Us
            </a>

            {/* <a className="navbar-link">Country</a> */}
            <a
              className="navbar-apply-button"
              onClick={() => setIsEnquiry(!isEnquiry)}
            >
              Book Appointment
            </a>
          </div>
        </nav>
      </div>
      <EnquiryForm isEnquiry={isEnquiry} setIsEnquiry={setIsEnquiry} />
    </div>
  );
}

export default Navbar;
