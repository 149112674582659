import React, { useEffect } from 'react'
import contactHead from '../../Images/dentalPic/contactHead.png'
import Testimonial from '../Testimonial/Testimonial'
import Appointment from '../Book/Appointment'

function Contact() {

  useEffect(()=>{
    window.scroll(0, 0);
  })
  return (
    <div className="container-fluid p-0">
        <div className="aligner-head">
        <h3 className="align-head-name">Contact</h3>
          <div className="align-child-2"></div>
          <div className="align-child-1">
          <img src={contactHead} className="align-main-img" />
          </div>
        </div>
        <div className='my-5'>

        <Appointment/>
        </div>
    </div>
  )
}

export default Contact