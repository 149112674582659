import React from 'react'
import './care.css'
import quality from '../../Images/dentalPic/betterQuality.png'
import safetytools from '../../Images/dentalPic/safetyTools.png'
import sterilization from '../../Images/dentalPic/dental-sterilization.png'
import tech from '../../Images/dentalPic/dentaltech.png'
import care from '../../Images/dentalPic/care.png'
import safety from '../../Images/dentalPic/dentalsafety.png'

function Care() {
    const specialCareData = [
        {
            name:"Better Quality",
            image:quality,
            content:"Quality is the cornerstone of our organization. At NeoSmile, our dedicated quality team ensures that our clinics consistently deliver the highest standards of care."
        },
        {
            name:"Equipment & Technology",
            image:safetytools,
            content:"NeoSmile's state-of-the-art clinic provides you with the most advanced dental technology and equipment. Our doctors undergo continuous training to stay at the forefront of the field."
        },
        {
            name:"Safety Equipment",
            image:safety,
            content:"NeoSmile now provides comprehensive PPE (Personal Protective Equipment) for all dentists and dental assistants."
        },
        {
            name:"4-Step Sterilization",
            image:sterilization,
            content:"For many years, NeoSmile's trademark process has set our clinic apart from all others."
        },
        {
            name:"10x Safety",
            image:tech,
            content:"Enhanced safety protocols combating 2019-nCoV Following WHO guidelines Ensuring a 100% sterile clinic For staff, patients, and attendants"
        },
        {
          name: "Personalized Care",
          image: care,
          content: "At NeoSmile, we prioritize each patient's unique needs by offering personalized care plans. Our attentive staff ensures that your comfort and health are at the heart of everything we do.",
          
        }
       ]

    
  return (
    <div className="container my-5">
      <div className="care-head">
        <h1 >Special Care On</h1>
      </div>
      <div className="row">
        {
            specialCareData.map((list)=>{
                return <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4">
                <div className='special-card'>
                  <img src={list.image} class="sp-img" alt="..." />
                  {
                    list.content&&<div className='special-card-content'>
                    <h5 class="code-blue">{list.name}</h5>
                    <p class="">
                      {list.content}
                    </p>
                  </div>
                  }
                  
                </div>
              </div>
            })
        }
        
      </div>
    </div>
  );
}

export default Care