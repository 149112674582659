import React from "react";
import "./implants.css";
import Implant from "../../../Images/dentalPic/dentalImplant.png";
import Implant2 from "../../../Images/dentalPic/implant.png";
import tooth from "../../../Images/dentalPic/tooth1.png";

function Implants() {
  return (
    <div className="container-fluid p-0">
      <div className="aligner-head">
        <img src={Implant} className="implant-main-img" />
        <div className="implant-child-2"></div>
        <div className="implant-child-1">
          <h3 className="implant-head-name">Dental Implants</h3>
        </div>
      </div>
      <div className="container mt-5 p-5">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <h3 className="implant-adbout-head">Dental Implants</h3>
            <p className="implant-about-content">
              Our implant dentists have many years of experience and extensive
              training, allowing them to complete the dental implant procedure
              efficiently, minimizing your discomfort each step of the way.
              Whether you need to replace a single tooth or multiple teeth,
              dental implants are the ultimate solution. You may not be able to
              save a tooth due to gum disease, tooth decay, excessive wear,
              injury or accident, poor oral hygiene, or certain medications.
              Dental implants look and feel like natural teeth and are superior
              to dentures as they are permanent. You can enjoy full chewing
              function and benefit from long-term results!
            </p>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div className="implant-about">
              <img src={Implant2} className="implant-about-img" />
              <div className="implant-about-tooth">
                <img src={tooth} />
              </div>
            </div>
          </div>
          <h2 className="invisible-content-head">
          The Dental Implant Procedure
          </h2>
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 my-5"></div>
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 my-5"></div>
        </div>
      </div>
    </div>
  );
}

export default Implants;
