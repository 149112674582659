import React, { useState } from "react";
import toast from "react-hot-toast";
import Select from "react-select";
import { Input, Label } from "reactstrap";
import { customStyles } from "../floatingMenu/EnquiryForm";
import "./appointment.css";
import sendEmail from "../Mail/sendMail";

export const treatmentOptions = [
  { label: "Aligners", value: "Aligners" },
  { label: "Dental Implant", value: "Dental Implant" },
  { label: "Root Canal Treatment", value: "Root Canal Treatment" },
  { label: "Teeth-Whitening", value: "Teeth-Whitening" },
  { label: "Family Dentistry", value: "Family Dentistry" },
  { label: "Resin Bonded", value: "Resin Bonded" },
  { label: "Sleep Dentistry", value: "Sleep Dentistry" },
  { label: "Cosmetic Dentistry", value: "Cosmetic Dentistry" },
  { label: "Gum Contouring & Lifting", value: "Gum Contouring & Lifting" },
  { label: "Invisalign", value: "Invisalign" },
  { label: "Porcelain veneers", value: "Porcelain veneers" },
  { label: "Gum Grafting", value: "Gum Grafting" },
  { label: "Others", value: "Others" },
];

function Appointment() {
  const [enquiryData, setEnquiryData] = useState({
    service:'',
    name:"",
    email:"",
    mobile:"",
    location:"",
    message:''
  });

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const validateMobile = (number) => {
    const pattern = /^[6789]\d{9}$/;
    return pattern.test(number);
  };

  const handleSubmit=()=>{
    if(enquiryData.name===""){
      return toast.error("Name Required")
    }
    if(enquiryData.email===""){
      return toast.error("Email required")
    }
    if(!validateEmail(enquiryData.email)){
      return toast.error("Please Enter valid Email")
    }
    if(!validateMobile(enquiryData.mobile)){
      return toast.error("Please Enter valid Mobile Number")
    }
    if(enquiryData.mobile===""){
      return toast.error("Mobile required")
    }
    if(enquiryData.location===""){
      return toast.error("Location required")
    }
    if(enquiryData.service===""){
      return toast.error("Location required")
    }
    if(enquiryData.message===""){
      return toast.error("Location required")
    }
    sendEmail(enquiryData)
    toast.success("Thank you for reaching out! We'll be in touch shortly.")
    setEnquiryData({
      service:'',
      name:"",
      email:"",
      mobile:"",
      location:"",
      message:''
    })
  }

  return (
    <section id="appointment">
      <div className="container form-font">
      <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div className="appointment-form">
            <h3>Book An Appointment</h3>
            <div className="row">
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 my-2">
                <Label for="name" className="fm-lb">
                  Name
                </Label>
                <Input
                  id="name"
                  name="name"
                  type="text"
                  value={enquiryData.name}
                  onChange={(e) =>
                    setEnquiryData({ ...enquiryData, name: e.target.value })
                  }
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12  my-2">
                <Label for="email" className="fm-lb">
                  Email
                </Label>
                <Input
                  id="email"
                  name="email"
                  type="email"
                  value={enquiryData.email}
                  onChange={(e) =>
                    setEnquiryData({ ...enquiryData, email: e.target.value })
                  }
                  
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 my-2">
                <Label for="mobile" className="fm-lb">
                  Mobile
                </Label>
                <Input
                  id="mobile"
                  name="mobile"
                  type="phone"
                  value={enquiryData.mobile}
                  onChange={(e) =>
                    setEnquiryData({ ...enquiryData, mobile: e.target.value })
                  }
                  
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                <Label for="" className="fm-lb">
                  Services{" "}
                </Label>
                <Select
                  id=""
                  name="name"
                  options={treatmentOptions}
                  styles={customStyles}
                  value={treatmentOptions?.filter((li)=>li.value===enquiryData.service)}
                  onChange={(e) =>
                    setEnquiryData({ ...enquiryData, service: e.value })
                  }
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 my-2">
                <Label for="name" className="fm-lb">
                  Location
                </Label>
                <Input
                  id="location"
                  name="location"
                  type="text"
                  value={enquiryData.location}
                  onChange={(e) =>
                    setEnquiryData({ ...enquiryData, location: e.target.value })
                  }
                  
                />
              </div>
              <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 my-2">
                <Label for="name" className="fm-lb">
                  Message
                </Label>
                <Input
                  id="location"
                  name="location"
                  type="text"
                  value={enquiryData.message}
                  onChange={(e) =>
                    setEnquiryData({ ...enquiryData, message: e.target.value })
                  }
                  
                />
              </div>
            </div>
            <div className="text-center">
              <button className="submit-btn" onClick={() => handleSubmit()}>
                Submit
              </button>
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
          <div className="appointment-right">
            <h2>Get In Touch</h2>
            <h2>
              Thank You for Choosing{" "}
              <strong>Neosmile Dental & Implant Centre</strong>
            </h2>

            <p>
              We value the trust you’ve placed in us for your dental care. Our
              team is committed to delivering top-quality care in a welcoming
              environment.
            </p>

            <p>
              Schedule your appointment today and take the next step toward a
              healthier, brighter smile. We look forward to serving you.
            </p>
            <div className="social-media">
              <div className="me-4">
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send?phone=7200943938&text=Hello%2C%20I%20Need%20a%20Appointment%20.%2C%20Please%20Contact%20me"
                >
                  <i class="fa fa-whatsapp fa-lg" aria-hidden="true"></i>
                </a>
              </div>
              <div className="me-4">
                <a
                  target="_blank"
                  href="https://www.instagram.com/neosmile_dental?igsh=bGtwOWNnZHRzN2R0"
                >
                  <i class="fa fa-instagram fa-lg" aria-hidden="true"></i>
                </a>
              </div>
              <div className="me-4">
                <a target="_blank" href="https://www.facebook.com/share/jxT6hXBuyeef9vXW/?mibextid=qi2Omg">

                <i class="fa fa-facebook fa-lg" aria-hidden="true"></i>
                </a>
              </div>
              <div className="me-4">
                <a  target="_blank" href="https://www.youtube.com/@Neosmiledental">

                <i class="fa fa-youtube-play fa-lg" aria-hidden="true"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    </section>
  );
}

export default Appointment;
