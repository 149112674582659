import React, { useState } from 'react'
import Select from 'react-select'
import { Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'
import './floating.css'
import toast from 'react-hot-toast'
import sendEmail from '../Mail/sendMail'
// import sendEmail from '../../mail/sendMail'

  export const treatmentOptions=[
    {label:"Aligners",value:"Aligners"},
    {label:"Dental Implant",value:"Dental Implant"},
    {label:"Root Canal Treatment",value:"Root Canal Treatment"},
    {label:"Teeth-Whitening",value:"Teeth-Whitening"},
    {label:"Family Dentistry",value:"Family Dentistry"},
    {label:"Resin Bonded",value:"Resin Bonded"},
    {label:"Sleep Dentistry",value:"Sleep Dentistry"},
    {label:"Cosmetic Dentistry",value:"Cosmetic Dentistry"},
    {label:"Gum Contouring & Lifting",value:"Gum Contouring & Lifting"},
    {label:"Invisalign",value:"Invisalign"},
    {label:"Porcelain veneers",value:"Porcelain veneers"},
    {label:"Gum Grafting",value:"Gum Grafting"},
    {label:"Others",value:"Others"},
  ]
  
  export const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? '#01A1D1' : 'white', // Change hover color
      color: state.isFocused ? 'white' : '#01A1D1', // Change text color on hover
      padding: 10,
      fontFamily:"Averia-Bold",
    }),
    control: (provided,state) => ({
      ...provided,
      margin: '10px 0',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,

    }),
  };

function EnquiryForm({isEnquiry,setIsEnquiry}) {
    // const [isEnquiry, setIsEnquiry] = useState(false);
    const [branchOptions,setBranchOptions]=useState()
    const [enquiryData,setEnquiryData]= useState({
      service:'',
      name:"",
      email:"",
      mobile:"",
      location:"",
      message:''
    })
  

    // const handleDegree=(e)=>{
    //   console.log(e)
    //   if(e.value==="Bachelor's Degree"){
    //     setEnquiryData({...enquiryData,degree:e.value})
    //     let filterResult =branchOptions.filter((op)=>op.value!=='MBA')
    //     setBranchOptions([...filterResult])
    //   }else{
    //     setEnquiryData({...enquiryData,degree:e.value})
    //   }
    // }

    const validateEmail = (email) => {
      const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return re.test(email);
    };
  
    const validateMobile = (number) => {
      const pattern = /^[6789]\d{9}$/;
      return pattern.test(number);
    };

    const handleSubmit=()=>{
      if(enquiryData.name===""){
        return toast.error("Name Required")
      }
      if(enquiryData.email===""){
        return toast.error("Email required")
      }
      if(!validateEmail(enquiryData.email)){
        return toast.error("Please Enter valid Email")
      }
      if(!validateMobile(enquiryData.mobile)){
        return toast.error("Please Enter valid Mobile Number")
      }
      if(enquiryData.mobile===""){
        return toast.error("Mobile required")
      }
      if(enquiryData.location===""){
        return toast.error("Location required")
      }
      if(enquiryData.service===""){
        return toast.error("Location required")
      }
      if(enquiryData.message===""){
        return toast.error("Location required")
      }
      sendEmail(enquiryData)
      setIsEnquiry(!isEnquiry)
      toast.success("Thank you for reaching out! We'll be in touch shortly.")
      setEnquiryData({
        service:'',
        name:"",
        email:"",
        mobile:"",
        location:"",
        message:''
      })
    }
  return (
    <Modal
    isOpen={isEnquiry}
    toggle={() => setIsEnquiry(!isEnquiry)}
    className="modal-content-des"
    centered
    size='md'
  >
    <ModalHeader toggle={() => setIsEnquiry(!isEnquiry)}>
      Book Appointment
    </ModalHeader>
    <ModalBody>
      <div className="container form-font">
        <div className="row">
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 my-2">
            <Label for="name" className="fm-lb">Name</Label>
            <Input
              id="name"
              name="name"
              type="text"
              value={enquiryData.name}
              onChange={(e)=>setEnquiryData({...enquiryData,name:e.target.value})}
              
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12  my-2">
            <Label for="email" className="fm-lb">Email</Label>
            <Input
              id="email"
              name="email"
              type="email"
              value={enquiryData.email}
              onChange={(e)=>setEnquiryData({...enquiryData,email:e.target.value})}
              
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 my-2">
            <Label for="mobile" className="fm-lb">Mobile</Label>
            <Input
              id="mobile"
              name="mobile"
              type="phone"
              value={enquiryData.mobile}
              onChange={(e)=>setEnquiryData({...enquiryData,mobile:e.target.value})}
              
            />
          </div>
        <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12">
            <Label for="category" className="fm-lb">Services </Label>
            <Select
              id="category"
              name="name"
              options={treatmentOptions}
              styles={customStyles}
              value={treatmentOptions?.filter((li)=>li.value===enquiryData.service)}
              onChange={(e)=>setEnquiryData({...enquiryData,service:e.value})}
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 my-2">
            <Label for="name" className="fm-lb">Location</Label>
            <Input
              id="location"
              name="location"
              type="text"
              value={enquiryData.location}
              onChange={(e)=>setEnquiryData({...enquiryData,location:e.target.value})}
              
            />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-12 col-xxl-12 my-2">
            <Label for="name" className="fm-lb">Message</Label>
            <Input
              id="location"
              name="location"
              type="text"
              value={enquiryData.message}
              onChange={(e)=>setEnquiryData({...enquiryData,message:e.target.value})}
             
            />
          </div>
        </div>
        <div className="text-center">
            <button className='submit-btn' onClick={()=>handleSubmit()}>Submit</button>
        </div>
      </div>
    </ModalBody>
  </Modal>
  )
}

export default EnquiryForm