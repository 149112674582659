import React, { useEffect } from "react";
import "./blog.css";
import video from "../../../dummyVideo.mp4";
import scaling from "../../videos/scaling.mp4";
import neosmile from "../../videos/neosmile.mp4";

import blogDoc from "../../Images/dentalPic/blogPicDoc.png";
import blogDental from "../../Images/dentalPic/blogDental.png";
import truncate from "lodash/truncate";

function Blog() {
  useEffect(()=>{
    window.scroll(0, 0);
  })
  return (
    <div className="container-fluid blog-bg p-0">
      <div className="blog-head">
        <h3>Blog</h3>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
            <div>
              <div class="card rounded blog-card">
                <div className="p-3">
                  <img src={blogDoc} className="img-fluid" />
                </div>
                <div class="card-body blog-desc">
                  <h5 class="card-title blog-card-head">
                    Best Doctor for Your Dental Needs at Neosmile Dental &
                    Implant Centre
                  </h5>
                  <p class="card-text">
                    {truncate(
                      "Finding the right doctor for your dental care is crucial for maintaining optimal oral health. At Neosmile Dental & Implant Centre, we pride ourselves on having a team of highly skilled and experienced doctors dedicated to providing exceptional dental care.",
                      { length: 90 }
                    )}
                  </p>
                  
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
            <div>
              <div class="card rounded  blog-card">
                <div className="p-3">
                  <img src={blogDental} className="img-fluid" />
                </div>
                <div class="card-body blog-desc">
                  <h5 class="card-title blog-card-head">
                    "Transform Your Smile with Invisalign:
                    <br /> The Clear Choice for Straight Teeth"
                  </h5>
                  <p class="card-text ">
                    {truncate(
                      "Your smile is one of your most important assets, and having straight teeth can boost your confidence and improve oral health. But traditional braces can be cumbersome and uncomfortable. That’s where Invisalign comes in—a revolutionary alternative to braces that offers a virtually invisible way to straighten your teeth.",
                      { length: 90 }
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
            <div>
              <div class="card rounded  blog-card">
                <div className="p-3">
                  <video src={video} controls className="img-fluid" loop />
                </div>
                <div class="card-body blog-desc">
                  <h5 class="card-title blog-card-head"> Straighten Your Smile with Braces</h5>
                  <p class="card-text ">
                  {truncate(
                      "A beautiful, straight smile is not just about aesthetics—it’s also crucial for maintaining good oral health. At Neosmile Dental & Implant Centre, we offer a variety of braces options tailored to your unique needs",
                      { length: 90 }
                    )}
                    
                  </p>
                 
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
            <div>
              <div class="card rounded  blog-card">
                <div className="p-3">
                  <video src={scaling} controls className="img-fluid" loop />
                </div>
                <div class="card-body blog-desc">
                  <h5 class="card-title blog-card-head">Scaling and Polishing</h5>
                  <p class="card-text ">
                  {truncate(
                      "Scaling and polishing are essential dental procedures that help maintain oral hygiene by removing plaque, tartar, and stains from the teeth. Scaling involves the removal of hardened plaque (calculus) from tooth surfaces and gumlines, preventing gum disease and tooth decay. Polishing follows scaling, smoothing the teeth to prevent bacteria from accumulating, leaving a clean, shiny surface. These procedures not only keep your teeth looking bright but also improve gum health, freshen your breath, and reduce the risk of serious dental problems. Regular scaling and polishing ensure a healthy and confident smile.",
                      { length: 90 }
                    )}
                    
                  </p>
                 
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4 col-xl-4 col-xxl-4">
            <div>
              <div class="card rounded  blog-card">
                <div className="p-3">
                  <video src={neosmile} controls className="img-fluid" loop />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Blog;
