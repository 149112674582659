import React, { useEffect, useState } from "react";
import align from "../../Images/dentalPic/aligners.png";
import alignAbout from "../../Images/dentalPic/align1.png";
import tooth from "../../Images/dentalPic/tooth1.png";
import treatmentSec from "../../Images/dentalPic/treatmentSec.png";
import invisible from "../../Images/dentalPic/invisible4.png";
import "./treatment.css";
import Testimonial from "../Testimonial/Testimonial";
import Appointment from "../Book/Appointment";
import { useParams } from "react-router-dom";
import { treatmentData } from "./TreatmentsData/treatments";
import SectionAnime from "../Animations/SectionAnimation/SectionAnime";

function Treatments() {
  const params = useParams();
  const [allTreatment, setAllTreatment] = useState(treatmentData);
  const [data, setData] = useState({});

  useEffect(() => {
    window.scroll(0, 0);
    if (params.id && allTreatment.length > 0) {
      setData(
        allTreatment.find((op) => {
          return op?.routeId === params?.id;
        })
      );
    }
  },);

  return (
    <div className="container-fluid p-0 mb-3">
      <div className="aligner-head">
       
        <h3 className="align-head-name">{data?.title}</h3>
        <div className="align-child-2"></div>
        <div className="align-child-1">
          <img src={`${data?.main_img}`} className="align-main-img" />
        </div>
      </div>
      <div className="container mt-5 p-5">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <h3 className="align-adbout-head">{data?.about_head}</h3>
            <p className="align-about-content">
              <div
                dangerouslySetInnerHTML={{ __html: data?.description }}
              ></div>
            </p>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div className="align-about">
              <img src={`${data?.about_img}`} className="align-about-img" />
              <div className="align-about-tooth">
                <img src={tooth} />
              </div>
            </div>
          </div>
          <h2 className="invisible-content-head">{data?.second_head}</h2>
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 my-5">
            <div className="align-content-sec">
              <img src={data?.content_img} className="align-content-img" />
            </div>
            {data?.sec_content_img === true ? (
              <div>
                <img src={treatmentSec} className="align-content-img" />
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 my-5">
            <div
              className="invisible-content"
              dangerouslySetInnerHTML={{ __html: data?.content }}
            ></div>
          </div>
        </div>
      </div>
      <Testimonial />
      <Appointment />
    </div>
  );
}

export default Treatments;
