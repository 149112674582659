import React from "react";
import align from "../../../Images/dentalPic/aligners.png";
import alignAbout from "../../../Images/dentalPic/align1.png";
import tooth from "../../../Images/dentalPic/tooth1.png";
import invisible from "../../../Images/dentalPic/invisible4.png";
import "./aligners.css";
import Testimonial from "../../Testimonial/Testimonial";
import Appointment from "../../Book/Appointment";

function Aligners() {
  return (
    <div className="container-fluid p-0">
      <div className="aligner-head">
        <img src={align} className="align-main-img" />
        <div className="align-child-2"></div>
        <div className="align-child-1">
          <h3 className="align-head-name">Aligners</h3>
        </div>
      </div>
      <div className="container mt-5 p-5">
        <div className="row">
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <h3 className="align-adbout-head">About Aligners</h3>
            <p className="align-about-content">
              Discover Invisalign at Neosmile Dental—an invisible way to
              straighten your teeth without braces. Feel free to contact us to
              schedule an appointment. Invisalign consists of a series of clear,
              removable aligners designed to straighten your teeth without metal
              wires or brackets. These aligners are created using a combination
              of orthodontic expertise and 3D computer imaging technology. You
              wear each set of aligners for about two weeks, removing them only
              to eat, drink, brush, and floss. As you replace each aligner with
              the next in the series, your teeth will gradually move, week by
              week, until they reach the final position. You'll need to visit us
              approximately every six weeks to ensure that your treatment is
              progressing as planned.
            </p>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6">
            <div className="align-about">
              <img src={alignAbout} className="align-about-img" />
              <div className="align-about-tooth">
                <img src={tooth} />
              </div>
            </div>
          </div>
          <h2 className="invisible-content-head">Invisalign - The Brace-Free Solution!</h2>
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 my-5">
            <div className="align-content-sec">
              <img src={invisible} className="align-content-img" />
            </div>
          </div>
          <div className="col-sm-12 col-md-12 col-lg-6 col-xl-6 my-5">
            <div className="invisible-content">
              <p>
                Invisalign is the latest alternative to braces, offering a
                series of clear aligners that rapidly straighten your smile. The
                days of uncomfortable metal braces are over—Invisalign is here
                to stay.
              </p>

              <h3>Benefits of Invisalign Compared to Traditional Braces:</h3>
              <ul>
                <li>
                  <strong>Removable:</strong> Easily removable, allowing you to
                  eat, floss, drink, or enjoy a special occasion.
                </li>
                <li>
                  <strong>Comfortable:</strong> No wires or metal brackets that
                  cause discomfort and pain.
                </li>
                <li>
                  <strong>Clear:</strong> Virtually invisible, so no one can
                  tell you’re wearing them!
                </li>
              </ul>

              <p>
                At <strong>Neosmile Dental & Implant Centre</strong>, our dental
                team develops a personalized treatment plan from your first
                consultation, including 4-6 week checkups and a schedule for new
                aligners.
              </p>

              <h3>How It Works:</h3>
              <p>
                Your dentist will see you for an initial consultation to
                determine if Invisalign is right for you. Then, we’ll create a
                treatment plan tailored to your goals and needs. Impressions of
                your teeth are taken and sent to the Invisalign laboratory,
                where your custom aligners are manufactured.
              </p>

              <p>
                At <strong>Neosmile Dental & Implant Centre</strong>, we use 3D
                imaging technology to show you what your teeth will look like
                before, during, and after treatment, helping you visualize the
                results you can expect.
              </p>

              <p>
                Once your treatment plan is finalized, we’ll provide you with
                custom-made aligners, which you’ll wear for two weeks before
                switching to the next set. Most patients require 18-36 aligners
                for a full treatment, spanning 9-18 months. You’ll wear the
                aligners day and night but can remove them to eat, brush, drink,
                or for special occasions.
              </p>

              <p>
                We’ll see you every 4-6 weeks for a checkup to monitor your
                progress and provide the next set of aligners. As treatment
                progresses, your teeth will gradually move into a healthy, even
                smile.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Testimonial/>
      <Appointment/>
    </div>
  );
}

export default Aligners;
