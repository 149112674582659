import React, { useEffect, useState } from "react";
import "./testimonial.css";
import Man from "../../Images/Icons/profile.png";
import {
  A11y,
  Autoplay,
  EffectCards,
  EffectCube,
  Navigation,
  Pagination,
  Scrollbar,
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/bundle";
// import qoute from "./../../Images/logo/testimonial-quote.png";
// import tqoute from "../../Images/icons/qoute.png";
// import tqouteleft from "../../Images/icons/tqoute.png";
import { truncate } from "lodash";
import RatingStar from "../RatingStar/RatingStar";

function Testimonial() {
  const [reviewData, setReviewData] = useState([]);

  // useEffect(() => {
  //   setReviewData(testimonialData);
  // }, []);
  const testimonialList = [
    {
      name: "Chinna Durai",
      img: Man,
      rating:5,
      review:"During my appointment, Dr. Bhagavan demonstrated excellent expertise. they took the time to thoroughly explain my dental issues and provided me with clear treatment options. Their communication was exceptional, and they made me feel at ease throughout the entire process.I was also pleasantly surprised by the pain management during the procedure. I experienced minimal discomfort, which was a relief."
    },
    {
      name: "Rajalakshmi",
      img: Man,
      rating:5,
      review:"Appreciable work and his friendly nature my daughter tend to feel calm and comfortable."
    },
    {
      name: "Selva meena",
      img: Man,
      rating:5,
      review:"My cavity problem fixed with root canal  effective results after the treatment, one of the best Dental clinic in and around Sholinganalur."
    },
    {
      name: "Dr. Chella",
      img: Man,
      rating:5,
      review:"Doctor was very calm in explaning  the procedure. Ambience was too good. Received a very satisfied treatment. As dental treatment are getting high here it's worth the penny.  Doctor gave a detailed outcome of procedure and procedures we're completed within short period of time. Overwhelmed with my smile correction . One of the Best Doctor in town."
    },
    {
      name: "Dwipan Roy",
      img: Man,
      rating:5,
      review:"The best doctor in OMR. He is very friendly and genuine person. Treated my family members dental issues. The prices are also nominal. Get the crown delivered on time as promised. Thankyou so much sir"
    },
    {
      name: "Natarajan tamilanda",
      img: Man,
      rating:4,
      review:"Recently I have undergone teeth extraction in this clinic. The doctor explained the procedure completely, he was very calm. The cost was also reasonable. I strongly recommend this clinic for teeth problems."
    },
    {
      name: "Brindha Raghavan",
      img: Man,
      rating:5,
      review:"Extremely good care and service by the Doctor. I got RCT done on oct'22 and also a dental filling. Explained every details of the procedure and broke my myth that RCT is a painful treatment. At least to me in the whole process I didn't feel the pain. The best dentist in town."
    },
  ];
  return (
    <section id="testimonial">
      <div className="container p-0 testimonial-bg">
        <div className="testimonial-bg-design">
          <div className="testimonial-head">
            <h3>Testimonial</h3>
            <p>
              Thousands of patients have placed their trust in Neosmile Dental &
              Implant Centre, where our dedicated team of experts provides
              personalized care, advanced treatments, and a seamless experience
              to guide each patient toward a healthier, brighter smile. From the
              first consultation to the final result, we are committed to making
              your dental journey comfortable and successful.
            </p>
          </div>
        </div>

        <Swiper
          modules={[Navigation, Pagination, Scrollbar, A11y, Autoplay]}
          spaceBetween={0}
          slidesPerView={3}
          autoplay={{
            delay: 4000,
            disableOnInteraction: true,
            pauseOnMouseEnter: true,
          }}
          loop
          grabCursor={true}
          pagination={{ clickable: true }}
          watchSlidesProgress
          breakpoints={{
            // when window width is >= 320px
            320: {
              slidesPerView: 1,
              spaceBetween: 20,
            },
            // when window width is >= 480px
            480: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            // when window width is >= 640px
            640: {
              slidesPerView: 2,
              spaceBetween: 40,
            },
            // when window width is >= 1024px
            1024: {
              slidesPerView: 3,
              spaceBetween: 50,
            },
            // when window width is >= 1280px
            1280: {
              slidesPerView: 3,
              spaceBetween: 60,
            },
          }}
          className="testimonial-wrapper"
        >
          {testimonialList.map((list) => {
            return (
              <SwiperSlide>
                <div className="">
                  <div class="card-new">
                    <div class="face front-face">
                      <img src={Man} alt="" class={`profile`} loading="lazy" />
                      <div class="pt-3 text-uppercase name">{list.name}</div>
                      <div class="designation">
                        <RatingStar value={list.rating} />
                      </div>
                      <div class="testimonial-review">{list.review}</div>
                    </div>
                  </div>

                  {/* <div className="testimonial-card-img">
                <img src={list.image} />
                <div className="testimonial-name">
                  <h3>{list.name}</h3>
                  <h5 className="tl-country">{list.course}</h5>
                  <h3 className="tl-country">{list.university}</h3>
                  <h5 className="tl-country"><i>{list.country}</i></h5>
                </div>
              </div>
              <div className="testimonial-content">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt 
                </p>
              </div> */}
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>
      </div>
    </section>
  );
}

export default Testimonial;
