import React, { useEffect } from 'react'
import { motion, useAnimation, useInView } from "framer-motion";

function SectionAnime({children}) {
  const ref = React.useRef(null);
  const controls = useAnimation();
    const isInView = useInView(ref, { once: true });
    const animations = {
        initial: { opacity: 0, y: 100 },
        animate: { opacity: 1, y: 0 },
        exit: { opacity: 0, y: -100 },
      };
    
      useEffect(() => {
        if (isInView) {
            controls.start("animate");
        } else {
            controls.start("initial");
        }
    }, [isInView, controls]);
  return (
    <>
    <motion.div
        ref={ref}
        variants={animations}
        initial="initial"
        animate={controls}
        exit="exit"
        transition={{ duration: 1 }}
      >
        {children}
      </motion.div>
    </>
  )
}

export default SectionAnime