import React from "react";
import "./ratingStar.css";

function RatingStar({ value }) {
  const totalStars = 5;
  const stars = [];

  // Loop through to generate stars
  for (let i = 1; i <= totalStars; i++) {
    if (i <= value) {
      stars.push(<i key={i} className="fa fa-star filled-star"></i>);
    } else if (i === Math.ceil(value) && value % 1 !== 0) {
      stars.push(<i key={i} className="fa fa-star-half-o filled-star"></i>);
    } else {
      stars.push(<i key={i} className="fa fa-star-o empty-star"></i>);
    }
  }

  return <div className="rating-stars">{stars}</div>;
}

export default RatingStar;
