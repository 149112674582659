import React from 'react'
import './specialities.css'
import {
    A11y,
    Autoplay,
    EffectCards,
    EffectCoverflow,
    EffectCube,
    Navigation,
    Pagination,
    Scrollbar,
  } from "swiper/modules";
  import { Swiper, SwiperSlide } from "swiper/react";
  import "swiper/css/bundle";

  import braces from '../../Images/specialities/braces.png'
  import child from '../../Images/specialities/child.png'
  import cosmetic from '../../Images/specialities/cosmetic.png'
  import grand from '../../Images/specialities/grand.png'
  import implant from '../../Images/specialities/implant.png'
import { useNavigate } from 'react-router-dom';

function Specialities() {
  const navigate = useNavigate()
    const specialList=[
        {
            name:"Braces & Invisible Aligners",
            image:braces,
            link:'aligners',
            description:"Achieve your best smile with NeoSmile Dental Clinic's Braces and Invisible Aligners. Our advanced orthodontic solutions discreetly straighten your teeth, giving you a healthy, radiant smile. Trust NeoSmile for a confident transformation."
        },
        {
            name:"Cosmetic Dentistry",
            image:cosmetic,
             link:'cosmetic-dentistry',
            description:"Achieve your best smile with NeoSmile Dental Clinic's Braces and Invisible Aligners. Our advanced orthodontic solutions discreetly straighten your teeth, giving you a healthy, radiant smile. Trust NeoSmile for a confident transformation."

        },
        {
            name:"Implant Dentistry",
            image:implant,
            link:'dental-implants',
            description:"Achieve your best smile with NeoSmile Dental Clinic's Braces and Invisible Aligners. Our advanced orthodontic solutions discreetly straighten your teeth, giving you a healthy, radiant smile. Trust NeoSmile for a confident transformation."

        },
        {
            name:"Pediatric Dentistry",
            image:child,
            description:"Achieve your best smile with NeoSmile Dental Clinic's Braces and Invisible Aligners. Our advanced orthodontic solutions discreetly straighten your teeth, giving you a healthy, radiant smile. Trust NeoSmile for a confident transformation."

        },
        {
            name:"Geriatric Dentistry",
            image:grand,
            description:"Achieve your best smile with NeoSmile Dental Clinic's Braces and Invisible Aligners. Our advanced orthodontic solutions discreetly straighten your teeth, giving you a healthy, radiant smile. Trust NeoSmile for a confident transformation."

        },
    ]
  return (
    <div className="container p-0 my-5">
      <div className="country-main">
        <h1>Our Specialities</h1>
        <Swiper
          effect={"coverflow"}
          grabCursor={true}
          centeredSlides={true}
          loop={true}
          slidesPerView={"auto"}
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          pauseOnMouseEnter: true,
          }}
          coverflowEffect={{
            rotate: 50,
            stretch: 0,
            depth: 100,
            modifier: 1,
            slideShadows: true,
          }}
          
          pagination={{clickable:true}}
          modules={[EffectCoverflow, Pagination, Autoplay]}
          className="country-swiper"
         
        >
          <div className="country-card">
            {
              specialList.map((list)=>{
                return  <SwiperSlide className="swiper-slide-uni">
                <div className="country-slide" onClick={()=>{list.link && navigate(`/treatments/${list.link}`)}}>
                <img src={list.image} alt="" className="country-image" loading="lazy" />
                <p className="country-content">
                  <h2>{list.name}</h2>
                </p>
                </div>
              </SwiperSlide>
              })
            }
           
            
          </div>
        </Swiper>
      </div>
    </div>
  )
}

export default Specialities