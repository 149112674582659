import align from "../../../Images/dentalPic/aligners.png";
import alignAbout from "../../../Images/dentalPic/align1.png";
import tooth from "../../../Images/dentalPic/tooth1.png";
import invisible from "../../../Images/dentalPic/invisible4.png";
import Implant from "../../../Images/dentalPic/dentalImplant.png";
import Implant2 from "../../../Images/dentalPic/implant.png";
import implantReplace from "../../../Images/dentalPic/implantsReplace.png";
import rctMain from "../../../Images/dentalPic/rctMain.png";
import rctAbout from "../../../Images/dentalPic/rctAbout.png";
import rct2 from "../../../Images/dentalPic/rct2.png";
import whitening from "../../../Images/dentalPic/whitening.png";
import whiteningAbout from "../../../Images/dentalPic/whiteningAbout.png";
import whiteningContent from "../../../Images/dentalPic/whiteningContent1.png";
import familyDentistry from "../../../Images/dentalPic/familyDenistry.png";
import familyDentistryAbout from "../../../Images/dentalPic/familyDentistryAbout.png";
import familyDentistryContent from "../../../Images/dentalPic/familyDentistryContent.png";
import resinHead from "../../../Images/dentalPic/resinHead.png";
import resinAbout from "../../../Images/dentalPic/resinAbout.png";
import resinContent from "../../../Images/dentalPic/resingContent.png";
import sleepHead from "../../../Images/dentalPic/sleepdentisthead.png";
import sleepAbout from "../../../Images/dentalPic/sleepdentistmain.png";
import sleepContent from "../../../Images/dentalPic/sleepdentistContent.png";
import cosmeticHead from "../../../Images/dentalPic/cosmeticHead.png";
import cosmeticMain from "../../../Images/dentalPic/cosmeticMain.png";
import cosmeticContent from "../../../Images/dentalPic/cosmeticContent (2).png";
import gumHead from "../../../Images/dentalPic/gumHead.png";
import gumAbout from "../../../Images/dentalPic/gumAbout.png";
import gumContent from "../../../Images/dentalPic/gumContent.png";
import porcelainHead from "../../../Images/dentalPic/porcelainHead1.png";
import porcelainAbout from "../../../Images/dentalPic/porcelainAbout.png";
import porcelainContent from "../../../Images/dentalPic/porcelainContent1.png";
import GumGraftingHead from "../../../Images/dentalPic/GumGraftingHead.png";
import GumGraftingAbout from "../../../Images/dentalPic/GumGraftingAbout.png";
import GumGraftingContent from "../../../Images/dentalPic/GumGraftingContent.png";

export const treatmentData = [
  {
    id: 1,
    title: "Aligners",
    routeId: "aligners",
    main_img: align,
    about_head: "About Aligners",
    about_img: alignAbout,
    tooth: tooth,
    description: `Discover Invisalign at Neosmile Dental—an invisible way to straighten your teeth without braces. Feel free to contact us to schedule an appointment. Invisalign consists of a series of clear, removable aligners designed to straighten your teeth without metal wires or brackets. These aligners are created using a combination of orthodontic expertise and 3D computer imaging technology. You wear each set of aligners for about two weeks, removing them only to eat, drink, brush, and floss. As you replace each aligner with the next in the series, your teeth will gradually move, week by week, until they reach the final position. You'll need to visit us approximately every six weeks to ensure that your treatment is progressing as planned.`,
    second_head: "Invisalign - The Brace-Free Solution!",
    content_img: invisible,
    sec_content_img:true,
    content: `<div className="invisible-content">
              <p>
                Invisalign is the latest alternative to braces, offering a
                series of clear aligners that rapidly straighten your smile. The
                days of uncomfortable metal braces are over—Invisalign is here
                to stay.
              </p>

              <h3>Benefits of Invisalign Compared to Traditional Braces:</h3>
              <ul>
                <li>
                  <strong>Removable:</strong> Easily removable, allowing you to
                  eat, floss, drink, or enjoy a special occasion.
                </li>
                <li>
                  <strong>Comfortable:</strong> No wires or metal brackets that
                  cause discomfort and pain.
                </li>
                <li>
                  <strong>Clear:</strong> Virtually invisible, so no one can
                  tell you’re wearing them!
                </li>
              </ul>

              <p>
                At <strong>Neosmile Dental & Implant Centre</strong>, our dental
                team develops a personalized treatment plan from your first
                consultation, including 4-6 week checkups and a schedule for new
                aligners.
              </p>

              <h3>How It Works:</h3>
              <p>
                Your dentist will see you for an initial consultation to
                determine if Invisalign is right for you. Then, we’ll create a
                treatment plan tailored to your goals and needs. Impressions of
                your teeth are taken and sent to the Invisalign laboratory,
                where your custom aligners are manufactured.
              </p>

              <p>
                At <strong>Neosmile Dental & Implant Centre</strong>, we use 3D
                imaging technology to show you what your teeth will look like
                before, during, and after treatment, helping you visualize the
                results you can expect.
              </p>

              <p>
                Once your treatment plan is finalized, we’ll provide you with
                custom-made aligners, which you’ll wear for two weeks before
                switching to the next set. Most patients require 18-36 aligners
                for a full treatment, spanning 9-18 months. You’ll wear the
                aligners day and night but can remove them to eat, brush, drink,
                or for special occasions.
              </p>

              <p>
                We’ll see you every 4-6 weeks for a checkup to monitor your
                progress and provide the next set of aligners. As treatment
                progresses, your teeth will gradually move into a healthy, even
                smile.
              </p>
            </div>`,
  },
  {
    id: 1,
    title: "Dental Implants",
    routeId: "dental-implants",
    main_img: Implant,
    about_head: "Dental Implants",
    about_img: Implant2,
    tooth: tooth,
    description: `Our implant dentists have many years of experience and extensive training, allowing them to complete the dental implant procedure efficiently, minimizing your discomfort each step of the way. Whether you need to replace a single tooth or multiple teeth, dental implants are the ultimate solution. You may not be able to save a tooth due to gum disease, tooth decay, excessive wear, injury or accident, poor oral hygiene, or certain medications. Dental implants look and feel like natural teeth and are superior to dentures as they are permanent. You can enjoy full chewing function and benefit from long-term results!`,
    second_head: "The Dental Implant Procedure",
    content_img: implantReplace,
    sec_content_img:true,
    content: `<div className="invisible-content">
              <p>
                Dental implants are titanium fixtures placed in the jawbone, replacing the root of the missing tooth. If more than one is required, multiple implants can serve as anchors to replace a segment or a full set of teeth. You will need four visits to Neosmile Dental & Implant Centre to complete the implant procedure, whether receiving a single or multiple implants.
              </p>

              <h3>Visit 1: Examination and X-rays</h3>
                <p>
                We’ll advise you on the suitability of implants and any alternative treatment solutions. The examination involves inspecting your mouth, gums, teeth, and jaws, plus taking X-rays. A cost plan is prepared and given to you for your consideration.
Approximate duration: 30-60 minutes
                </p>
<h3>Visit 2: Implant Placement Surgery</h3>
              <p>
               During this visit, the dental implant(s) will be surgically placed into your jawbone. The area will be numbed with local anesthesia to ensure your comfort. After the implant is placed, a healing cap is attached to protect it during the healing process. You will be given post-operative care instructions to follow during your recovery period.
Approximate duration: 60-90 minutes
              </p>

              <h3>Visit 3: Follow-up and Healing Check</h3>
              <p>
                A few weeks after the implant placement, you will return for a follow-up visit. We will check the healing progress to ensure that the implant is integrating well with your jawbone. At this stage, we may also take additional X-rays to confirm proper placement. Any necessary adjustments will be made.
Approximate duration: 30-45 minutes
              </p>
<h3>Visit 4: Final Restoration</h3>
              <p>
                Once the implant has fully integrated with the bone (a process known as osseointegration), the final restoration will be placed. This involves attaching an abutment to the implant, which serves as the connection between the implant and the crown. A custom-made crown, designed to match your natural teeth, will then be fitted onto the abutment, completing the procedure.
Approximate duration: 45-60 minutes
              </p>

              
            </div>`,
  },
  {
    id: 1,
    title: "Root Canal Treatment",
    routeId: "root-canal-treatment",
    main_img: rctMain,
    about_head: "About Root Canal Treatment",
    about_img: rctAbout,
    tooth: tooth,
    description: `At Neosmile Dental & Implant Centre, our experienced endodontists provide expert care for root canal treatments, ensuring that you receive the highest level of comfort and care throughout the process.

A root canal treatment is necessary when the pulp inside your tooth becomes inflamed or infected due to deep decay, repeated dental procedures, cracks, or chips in the tooth. If left untreated, this infection can lead to severe pain or an abscess. Root canal treatment allows you to save your natural tooth, avoiding the need for extraction and maintaining your smile.`,
    second_head: "The Root Canal Treatment Procedure",
    content_img: rct2,
    sec_content_img:true,
    content: `<div className="invisible-content">
              <p>
                Root canal treatment involves removing the infected or inflamed pulp, cleaning the inside of the tooth, and sealing it to prevent further infection. The procedure typically requires three visits to Neosmile Dental & Implant Centre.
              </p>

              <h3>Visit 1: Diagnosis and Preparation</h3>
                <p>
                During the first visit, our dentist will examine your tooth and take X-rays to determine the extent of the infection. We will discuss the treatment plan with you, including the costs and any alternative options. Local anesthesia will be administered to ensure your comfort, and the affected tooth will be isolated to keep it dry and free of saliva during the procedure.
Approximate duration: 45-60 minutes
                </p>
<h3>Visit 2: Cleaning and Shaping the Root Canals</h3>
              <p>
              In the second visit, the infected or inflamed pulp is carefully removed from the tooth. The root canals are then cleaned, shaped, and disinfected. Once the canals are prepared, they are filled with a biocompatible material called gutta-percha, which seals the canals to prevent further infection. A temporary filling will be placed to protect the tooth until the final restoration.
Approximate duration: 60-90 minutes
              </p>

              <h3>Visit 3: Final Restoration</h3>
              <p>
                The final visit involves restoring the tooth to its full function. The temporary filling is removed, and the tooth is prepared for a crown or filling. A custom-made crown is often recommended to provide additional strength and protection to the treated tooth. This ensures that your tooth is fully restored and can function like a natural tooth.
Approximate duration: 45-60 minutes
              </p>
              <p>
              Root canal treatment is a safe and effective way to save your natural tooth and eliminate pain. If you’re experiencing tooth pain or sensitivity, contact us today to schedule a consultation and learn more about how root canal treatment can help you.
              </p>

              
            </div>`,
  },
  {
    id: 1,
    title: "Teeth Whitening",
    routeId: "teeth-whitening",
    main_img: whitening,
    about_head: "About Teeth Whitening",
    about_img: whiteningAbout,
    tooth: tooth,
    description: `At Neosmile Dental & Implant Centre, we offer professional teeth whitening treatments designed to give you a brighter, more confident smile. Our experienced dentists use the latest techniques to safely and effectively remove stains and discoloration, helping you achieve the dazzling smile you’ve always wanted.

Teeth whitening is a popular cosmetic dental procedure that lightens teeth and helps to remove stains caused by various factors, including coffee, tea, tobacco, red wine, and aging. While there are many over-the-counter whitening products available, professional teeth whitening provides faster, more consistent, and longer-lasting results.`,
    second_head: "The Teeth Whitening Procedure",
    content_img: whiteningContent,
    sec_content_img:true,
    content: `<div className="invisible-content">
              <p>
               Our teeth whitening process is safe, quick, and tailored to meet your specific needs. The treatment typically requires two visits to Neosmile Dental & Implant Centre.
              </p>

              <h3>Visit 1: Consultation and Customization</h3>
                <p>
                During your first visit, our dentist will evaluate your teeth and discuss your whitening goals. We’ll review your dental history to ensure you’re a good candidate for the procedure and explain the options available. Impressions of your teeth may be taken to create custom whitening trays that fit perfectly and provide even coverage.
Approximate duration: 30-45 minutes
                </p>
<h3>Visit 2: Whitening Treatment</h3>
              <p>
              On your second visit, the actual whitening treatment will be performed. Your gums and lips will be protected with a special barrier to prevent irritation. A professional-grade whitening gel is then applied to your teeth. Depending on the chosen method, a special light or laser may be used to activate the gel, speeding up the whitening process. The treatment may involve multiple applications of the gel in one session to achieve the desired level of brightness.
Approximate duration: 60-90 minutes
              </p>

              <h3>At-Home Whitening Kits</h3>
              <p>
                In addition to in-office treatments, we also offer customized at-home whitening kits. These kits include your custom trays and professional-grade whitening gel, allowing you to whiten your teeth at your convenience. You will receive detailed instructions on how to use the kit safely and effectively, and we’ll schedule a follow-up visit to monitor your progress.
              </p>
              <p>
             Professional teeth whitening can dramatically enhance your smile, boosting your confidence and leaving a lasting impression. Contact us today to schedule your consultation and discover the difference professional teeth whitening can make!
              </p>
            </div>`,
  },
  {
    id: 1,
    title: "Family Dentistry",
    routeId: "family-dentistry",
    main_img: familyDentistry,
    about_head: "About Family Dentistry",
    about_img: familyDentistryAbout,
    tooth: tooth,
    description: `Help your children enjoy a lifetime of healthy teeth and good oral hygiene. Habits form early, and the state of your child’s oral health must be maintained from a young age, even when they have baby teeth.
Kids learn a great deal from their parents, including how they respond to dental procedures and habits. You can positively influence your child’s ability to have great oral health by starting early. We recommend that they have a dental visit by their first birthday, allowing us to monitor their oral development and growth. Remember, prevention is the best possible medicine to sustain optimum oral health!
`,
    second_head: "Avoid Future Problems",
    content_img: familyDentistryContent,
    sec_content_img:false,
    content: `<div className="invisible-content">
              <p>
              At NEOSMILE DENTAL AND IMPLANT CENTRE, we appreciate how important dental health is for children. Although they may not have their adult teeth yet, damaging baby teeth can have harmful implications for their general health. Some common problems we see include:
              </p>
              <ul>
                <li>
                  <strong>Tooth decay</strong> 
                </li>
                <li>
                  <strong>Tooth sensitivity</strong>
                </li>
                <li>
                  <strong>Dental emergencies</strong> 
                </li>
                <li>
                  <strong>Gingivitis and Gum disease</strong> 
                </li>
                <li>
                  <strong>Anxiety and fear of the dentist</strong> 
                </li>
              </ul>


             
                <p>
               These issues alone or in tandem can have concerning consequences for your child’s overall health. Particularly for children, dental health is of particular importance due to the possibility of consequences far into adulthood. Children can experience pain and discomfort, difficulty sleeping, growth and development issues, poor self esteem and social difficulties, all stemming from an underlying dental issue.
                </p>
              <p>
              The best and most effective way to avoid any of these issues is to ensure your child visits the dentist regularly from their first birthday onwards. Here at NEOSMILE DENTAL AND IMPLANT CENTRE, we specialise in family dentistry and are well equipped to identify and treat any current or future dental issues. We understand that many children dread coming to the dentist and therefore make it as quick, painless and fun as possible. Our gentle dentists have a wonderful bedside manner and are well-versed in putting children at ease.
              </p>
            </div>`,
  },
  {
    id: 1,
    title: "Resin Bonded",
    routeId: "resin-bonded",
    main_img: resinHead,
    about_head: "About Resin Bonded",
    about_img: resinAbout,
    tooth: tooth,
    description: `Resin-bonded veneers, also known as composite veneers, are a popular cosmetic dental solution designed to enhance the appearance of your teeth. These veneers are made from a high-quality composite resin material that is carefully applied to the front surface of your teeth, improving their shape, color, and overall aesthetic.

Unlike traditional veneers, which often require significant tooth reduction, resin-bonded veneers are minimally invasive, preserving more of your natural tooth structure. This makes them an excellent choice for those looking to address cosmetic concerns such as chipped, stained, or slightly misaligned teeth without undergoing extensive dental procedures.

The application process is typically completed in a single visit, where the resin material is meticulously shaped and polished to blend seamlessly with your natural teeth. The result is a beautiful, natural-looking smile that enhances your confidence and leaves a lasting impression.

Resin-bonded veneers are not only aesthetically pleasing but also durable and versatile, providing a cost-effective alternative to porcelain veneers. They offer immediate results and can be adjusted or repaired easily, making them a practical and popular choice for many patients.
`,
    second_head: "Resin Bonded",
    content_img: resinContent,
    sec_content_img:true,
    content: `<div className="invisible-content">
  <p>
    Resin-bonded veneers, also known as composite veneers, offer a minimally invasive solution to enhance the appearance of your smile. This procedure is ideal for addressing a variety of cosmetic concerns, including chipped, discolored, or slightly misaligned teeth. The treatment is customized to blend seamlessly with your natural teeth, providing a beautiful and lasting result.
  </p>

  <h3>What are Resin-Bonded Veneers?</h3>
  <p>
    Resin-bonded veneers are thin layers of tooth-colored composite resin that are carefully applied to the front surface of your teeth. The resin is shaped and polished to match your natural teeth, creating a flawless, natural-looking smile. This treatment is an excellent alternative to porcelain veneers, offering similar aesthetic benefits with less tooth preparation.
  </p>

  <h3>The Resin Bonding Procedure</h3>
  <p>
    The resin bonding procedure typically requires only one visit to Neosmile Dental & Implant Centre. During your appointment, our dentist will first clean and prepare your teeth. The surface of the teeth is gently etched to create a strong bond with the resin. The composite material is then applied, sculpted to the desired shape, and hardened using a special curing light. Finally, the resin is polished to achieve a smooth, natural appearance.
    <br><br>
    Approximate duration: 60-120 minutes
  </p>

  <h3>Benefits of Resin Bonding</h3>
  <p>
    Resin bonding is a versatile and cost-effective solution for improving your smile. Some of the key benefits include:
    <ul>
      <li>Minimally invasive with little to no tooth reduction</li>
      <li>Immediate results in a single visit</li>
      <li>Customizable to match the color and shape of your natural teeth</li>
      <li>Can be used to address multiple cosmetic concerns</li>
      <li>Reversible treatment with the ability to make adjustments over time</li>
    </ul>
  </p>

  <h3>Aftercare and Maintenance</h3>
  <p>
    Proper care and maintenance of your resin-bonded veneers are essential to ensure their longevity. We recommend regular dental check-ups and a consistent oral hygiene routine, including brushing and flossing. Avoiding habits like nail-biting and using your teeth as tools can help prevent damage to the veneers. With proper care, resin-bonded veneers can last several years, providing you with a confident and beautiful smile.
  </p>

  <p>
    If you're considering resin-bonded veneers, contact Neosmile Dental & Implant Centre today to schedule a consultation. Our team will assess your needs and help you achieve the smile you've always wanted.
  </p>
</div>`,
  },
  {
    id: 1,
    title: "Sleep Dentistry",
    routeId: "sleep-dentistry",
    main_img: sleepHead,
    about_head: "About Sleep Dentistry",
    about_img: sleepAbout,
    tooth: tooth,
    description: `At Neosmile Dental & Implant Centre, we understand that dental anxiety or fear can prevent many patients from receiving the dental care they need. That's why we offer sleep dentistry, also known as sedation dentistry, to help you relax and feel comfortable during your dental procedures. Our compassionate team is committed to making your experience as stress-free as possible, ensuring you receive the care you need without fear or discomfort.

Sleep dentistry is ideal for patients who experience significant anxiety, have a low pain threshold, or require extensive dental work. It allows you to undergo treatments while in a deeply relaxed state, so you can leave our clinic with a healthier smile and peace of mind.
`,
    second_head: "What is Sleep Dentistry?",
    content_img: sleepContent,
    sec_content_img:true,
    content: `<div className="invisible-content">
              <p>
             Sleep dentistry involves the use of sedative medications to help patients relax during dental procedures. Depending on your needs and the type of procedure, different levels of sedation may be used, ranging from mild relaxation to full sleep. This approach makes it easier for you to undergo necessary treatments without the stress and anxiety that often accompany dental visits.</p>
              <h3>Types of Sedation Used in Sleep Dentistry</h3>
              <h4>1. Nitrous Oxide (Laughing Gas):</h4>
                <p>
             A mild sedative inhaled through a mask, helping you relax while remaining awake. The effects wear off quickly, so you can resume normal activities soon after your appointment.</p>
              <h4>2. Oral Sedation:</h4>
              <p>
             A prescribed pill taken before your appointment to help you feel calm and drowsy, while still conscious during the procedure.</p>
              <h4>3. IV Sedation: :</h4>
              <p>
             Administered through a vein, providing a deeper level of relaxation. You’ll be semi-conscious and may not remember much of the procedure</p>
              <h4>4. General Anesthesia:</h4>
              <p>
             For extreme anxiety or complex procedures, this option puts you completely asleep, usually in a hospital setting.</p>
             <p>
             Sleep dentistry is ideal for those with dental anxiety, low pain tolerance, or who need extensive work. Contact us today to learn how sleep dentistry can make your visit more comfortable.</p>
            </div>`,
  },
  {
    id: 1,
    title: "Cosmetic Dentistry",
    routeId: "cosmetic-dentistry",
    main_img: cosmeticHead,
    about_head: "About Cosmetic Dentistry",
    about_img: cosmeticMain,
    tooth: tooth,
    description: `At Neosmile Dental & Implant Centre, our cosmetic dentistry services are designed to enhance the beauty of your smile. From teeth whitening to veneers, our treatments help you achieve a confident, radiant smile.
`,
    second_head: "What to Expect During Your Visits:",
    content_img: cosmeticContent,
    sec_content_img:false,
    content: `<div className="invisible-content">
    <h3>Visit 1: Consultation and Treatment Planning</h3>
      <p>
     We will discuss your cosmetic goals, perform a thorough examination, and create a personalized treatment plan. This visit may include imaging and diagnostic assessments to determine the best approach for your smile enhancement.
Approximate duration: 30-60 minutes
      </p>
<h3>Visit 2: Treatment Application</h3>
    <p>
    For services like teeth whitening, dental bonding, or veneers, the treatment will be applied. Depending on the procedure, this visit may involve multiple steps, such as customizing veneers or applying bonding material.
Approximate duration: 60-90 minutes
    </p>

    <h3>Visit 3: Follow-up and Final Adjustments</h3>
    <p>
      A follow-up visit ensures that the results meet your expectations. We’ll make any necessary adjustments and provide aftercare instructions to maintain your new smile.
Approximate duration: 30-45 minutes
    </p>
    <p>
    Cosmetic dentistry can transform your smile and boost your confidence. Contact us today to schedule your consultation and take the first step toward achieving the smile you’ve always wanted.
    </p>
  </div>`,
  },
  {
    id: 1,
    title: "Gum Countering and Lifiting",
    routeId: "Gum-Contouring-and-Lifting",
    main_img: gumHead,
    about_head: "About Gum Countering and Lifiting",
    about_img: gumAbout,
    tooth: tooth,
    description: `<div>
      <p>At Neosmile Dental & Implant Centre, we offer gum contouring and lifting procedures to enhance the appearance of your gums and achieve a balanced, beautiful smile. These treatments are designed to reshape and refine your gum line, improving both aesthetics and function.</p>
      <h5>Types of Gum Contouring and Lifting Services We Offer:</h5>
      <ul>
      <li>Gum Contouring: Also known as gum reshaping, this procedure involves removing excess gum tissue to create a more even and aesthetically pleasing gum line. It’s ideal for correcting a “gummy” smile or uneven gums.</li>
      <li>Gum Lifting: This technique involves lifting and repositioning the gum tissue to improve the overall symmetry of your smile. It can also address issues like gum recession or asymmetry.</li>
      </ul>
    </div>
`,
    second_head: "What to Expect During Your Visits:",
    content_img: gumContent,
    sec_content_img:false,
    content: `<div className="invisible-content">
    <h3>Visit 1: Consultation and Treatment Planning</h3>
      <p>
     During your initial visit, we will assess your gum health and discuss your cosmetic goals. We will create a personalized treatment plan based on your needs and may take digital images or impressions for precise planning.
Approximate duration: 30-45 minutes
      </p>
<h3>Visit 2: Gum Contouring and Lifting Procedure</h3>
    <p>
    The procedure will be performed under local anesthesia to ensure your comfort. For gum contouring, excess tissue is gently removed and reshaped. For gum lifting, tissue is repositioned to achieve the desired contour. Post-procedure care instructions will be provided.
Approximate duration: 60-90 minutes
    </p>

    <h3>Visit 3: Follow-up and Evaluation</h3>
    <p>
    A follow-up visit ensures that your gums are healing properly and that the results meet your expectations. We’ll check for any signs of complications and make any necessary adjustments to enhance your results.
Approximate duration: 30 minutes
    </p>
    <p>
    Gum contouring and lifting can transform the appearance of your smile by creating a harmonious balance between your gums and teeth. Contact us today to schedule a consultation and learn how these treatments can benefit you.</p>
  </div>`,
  },
  {
    id: 1,
    title: "Porcelain Veneers",
    routeId: "porcelain-veneers",
    main_img: porcelainHead,
    about_head: "About Porcelain Veneers",
    about_img: porcelainAbout,
    tooth: tooth,
    description: `<div>
      <p>At Neosmile Dental & Implant Centre, porcelain veneers are one of our most popular cosmetic dentistry options. These custom-made, thin porcelain shells are designed to cover the front surface of your teeth, correcting imperfections and giving you a flawless, natural-looking smile.</p>
      <h5>What Porcelain Veneers Can Address:</h5>
      <ul>
      <li>Discolored Teeth: Veneers can mask stains that do not respond to whitening treatments, giving you a brighter smile.</li>
      <li>Chipped or Worn Teeth: Veneers can cover and protect teeth that are chipped, cracked, or worn down.</li>
      <li>Gaps and Spaces: Veneers can close small gaps between teeth, improving the overall alignment of your smile.</li>
      <li>Misshapen or Uneven Teeth: Veneers can reshape teeth that are irregular or uneven, creating a more symmetrical appearance.</li>
      </ul>
    </div>
`,
    second_head: "What to Expect During Your Visits:",
    content_img: porcelainContent,
    sec_content_img:true,
    content: `<div className="invisible-content">
    <h3>Visit 1: Consultation and Treatment Planning</h3>
      <p>
     During your initial visit, we’ll discuss your aesthetic goals and evaluate your teeth to determine if porcelain veneers are right for you. We’ll take impressions or digital scans of your teeth and create a customized treatment plan.
Approximate duration: 30-60 minutes
      </p>
<h3>Visit 2: Tooth Preparation and Temporary Veneers</h3>
    <p>
    In this visit, we’ll prepare your teeth by removing a small amount of enamel to make room for the veneers. Impressions of your teeth are taken again, and temporary veneers may be placed to protect your teeth while your custom veneers are being fabricated.
Approximate duration: 60-90 minutes
    </p>

    <h3>Visit 3: Veneer Placement</h3>
    <p>
   Once your custom veneers are ready, we’ll remove the temporary veneers and bond the porcelain veneers to your teeth using a strong adhesive. We’ll make any final adjustments to ensure a perfect fit and natural appearance.
Approximate duration: 60-90 minutes
    </p>
    <h3>Visit 4: Follow-up and Final Adjustments</h3>
    <p>
  A follow-up visit is scheduled to ensure that your veneers are comfortable and properly aligned. We’ll make any necessary adjustments and provide you with care instructions to maintain your new smile.
Approximate duration: 30 minutes
    </p>
    <p>
    Porcelain veneers can completely transform your smile, giving you a confident, radiant appearance. Contact us today to schedule a consultation and explore how veneers can help you achieve the smile of your dreams.</p>
  </div>`,
  },
  {
    id: 1,
    title: "Gum Grafting",
    routeId: "gum-grafting",
    main_img: GumGraftingHead,
    about_head: "About Gum grafting",
    about_img: GumGraftingAbout,
    tooth: tooth,
    description: `<div>
      <p>At Neosmile Dental & Implant Centre, we offer gum grafting to treat gum recession and improve the health and appearance of your gums. This procedure is designed to restore lost gum tissue, protect your teeth, and enhance your smile.</p>
      <h5>What Gum Grafting Can Address:</h5>
      <ul>
      <li>Gum Recession: Gum grafting can cover exposed roots caused by gum recession, reducing sensitivity and protecting your teeth from further damage.</li>
      <li>Improving Gum Aesthetics: The procedure helps in reshaping and restoring the natural gum line, resulting in a more balanced and attractive smile.</li>
      <li>Preventing Further Recession: By adding healthy tissue to receded areas, gum grafting helps prevent further gum recession and protects your oral health.</li>
      </ul>
    </div>
`,
    second_head: "What to Expect During Your Visits:",
    content_img: GumGraftingContent,
    sec_content_img:false,
    content: `<div className="invisible-content">
    <h3>Visit 1: Consultation and Treatment Planning</h3>
      <p>
     During your first visit, we’ll evaluate the extent of gum recession and discuss your treatment options. We’ll create a customized plan that suits your specific needs and take any necessary images or impressions.
Approximate duration: 30-45 minutes
      </p>
<h3>Visit 2: Gum Grafting Procedure</h3>
    <p>
    The gum grafting procedure is performed under local anesthesia to ensure your comfort. Tissue from your palate or a donor source will be grafted onto the receded areas, covering exposed roots and restoring your gum line. Post-procedure care instructions will be provided to ensure proper healing.
Approximate duration: 60-90 minutes
    </p>

    <h3>Visit 3: Follow-up and Healing Assessment</h3>
    <p>
   A follow-up visit will be scheduled to monitor your healing process. We’ll check the grafted area for proper integration and ensure that your gums are healing as expected. Any necessary adjustments or additional care instructions will be provided.
Approximate duration: 30 minutes
    </p>
    <p>
    Gum grafting can significantly improve your oral health and the appearance of your smile. If you’re concerned about gum recession or would like to enhance your gum line, contact us today to schedule a consultation and learn how gum grafting can benefit you.

</p>
  </div>`,
  },
];
