import React from "react";
import "./footer.css";
import logo from "../../Images/Logo/neosmile_bg.png";
import address from "../../Images/Icons/location.png"
import phone from "../../Images/Icons/mobile.png"
import email from "../../Images/Icons/mail.png"
import { useNavigate } from "react-router-dom";
import SectionAnime from "../Animations/SectionAnimation/SectionAnime";
import { treatmentList } from "../Navbar/Navbar";

function Footer() {
  const navigate = useNavigate()
  return (
    <div className="container-fluid p-0 footer">
      <div className="footer-container">
        <div className="footer-logo-details">
          <SectionAnime>
          <img src={logo} alt="logo" className="footer-logo" loading="lazy" />
          <h6 className="mt-4">
            Since 2021, NeoSmile Dental Clinic has been brightening smiles with
            expert care and commitment.
          </h6>
          </SectionAnime>
          <SectionAnime>
          <div className="footer-social-details">
          <a
              href="https://api.whatsapp.com/send?phone=7200943938&text=Hello%2C%20I%20Need%20a%20Appointment%20.%2C%20Please%20Contact%20me"
              target="_blank"
              className="footer-fb"
            >
              <i className="fa fa-whatsapp fa-lg"></i>
            </a>
            <a
              href="https://www.facebook.com/share/jxT6hXBuyeef9vXW/?mibextid=qi2Omg"
              target="_blank"
              className="footer-social"
            >
              <i className="fa fa-facebook fa-lg"></i>
            </a>
            <a
              href="https://www.instagram.com/neosmile_dental?igsh=bGtwOWNnZHRzN2R0"
              target="_blank"
              className="footer-social"
            >
              <i className="fa fa-instagram fa-lg"></i>
            </a>
            <a
              href="https://www.youtube.com/@Neosmiledental"
              target="_blank"
              className="footer-social"
            >
              <i className="fa fa-youtube-play fa-lg"></i>
            </a>
          </div>
          </SectionAnime>
        </div>
        <div className="footer-company-details">
          <SectionAnime>
          <div className="footer-address">
            <img src={address} className="ft-icon" loading="lazy" />
            No.30 Annai Indira Gandhi Street, Shollinganallur Chennai-600097
          </div>
          </SectionAnime>
          <SectionAnime>

          <div className="footer-contact">
            <img src={phone} className="ft-icon" />
            <a href="tel:7200943938">7200943938</a>
          </div>
          </SectionAnime>
          <SectionAnime>
          <div className="footer-mail">
            <a href="mailto:neosmiledentalomr@gmail.com">
              <img src={email} className="ft-icon" />
              Neosmile Dental OMR
            </a>
          </div>
          </SectionAnime>
        </div>
        <div className="footer-link-details">
          <div className="d-flex">
            <ul className="footer-links me-5">
              <li>
                <a onClick={() => navigate("/")}>Home</a>
              </li>
              <li>
                <a onClick={() => navigate("/about")}>About Us</a>
              </li>
              <li>
                <li className="footer-accordion">
                  <a onClick={() => navigate("/blog")}>Blog</a>
                </li>
              </li>
              <li>
                <a onClick={() => navigate("/contact")}>Contact Us</a>
              </li>
            </ul>
            <ul className="footer-links ms-3">
              {
                treatmentList.map((list)=>{
                  return <li>
                  <a onClick={() => navigate(`/treatments/${list.link}`)}>{list.name}</a>
                </li>
                })
              }
            </ul>
          </div>
        </div>
        <div>
         <div className="mt-4">
         <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3889.3132699139833!2d80.22602237454531!3d12.887566616716098!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a525b40e595b833%3A0x90a145a0ad547d96!2sNEOSMILE%20DENTAL%20AND%20IMPLANT%20CENTRE!5e0!3m2!1sen!2sin!4v1723878070456!5m2!1sen!2sin"
            style={{border:0}}
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
            className="neo-map"
          ></iframe>
         </div>
        </div>
      </div>
      <div className="copy-ryt">
        &copy; {new Date().getFullYear()} Neosmile | All Rights
        Reserved | powered by GridRealitySolutions
      </div>
    </div>
  );
}

export default Footer;
