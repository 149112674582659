import React, { useEffect } from "react";
import "./about.css";
import doctor from "../../Images/dentalPic/doctor.png";
import doctor1 from "../../Images/dentalPic/doctor1.jpg";
import Kaviya from "../../Images/dentalPic/KaviyaDoctor.jpg";
import Bhagavan from "../../Images/dentalPic/Dr.Bhagavan2.png";
import shreya from "../../Images/dentalPic/Dr.Shreya Rajesh.png";
import receptionist from "../../Images/dentalPic/receptionist.png";
import aboutHead from "../../Images/dentalPic/abhead.png";
import Testimonial from "../Testimonial/Testimonial";

const doctorsData =[
  {
    name:'Dr.Bhagavan Dhasa',
    image:Bhagavan,
    side:'left',
    content:"Dr. Bhagavan Dhasa is the Chief Dental Surgeon at Neosmile Dental & Implant Centre. With extensive professional expertise, Dr. Dhasa has made significant contributions in the field of dentistry. His commitment to excellence ensures that every patient receives top-quality treatment, delivered with meticulous attention to detail in a comfortable and caring environment."
  },
  {
    name:'Dr. Kaviyah Venkatachalam - Oral Pathologist',
    image:Kaviya,
    side:'right',
    content:"Dr. Kaviyah Venkatachalam is the oral pathologist at Neosmile Dental & Implant Centre, specializing in diagnosing and treating oral diseases. With a deep understanding of oral pathology, Dr. Venkatachalam is dedicated to early detection and effective management of oral conditions, ensuring her patients receive prompt and accurate care."
  },
  {
    name:'Dr. Abishek Mohan(BDS,MOI) - Implantologist',
    image:doctor1,
    side:'left',
    content:" Dr. Abishek Mohan is a skilled Implantologist specializing in advanced dental implant procedures. With a solid background in both general and implant dentistry, Dr.Abishek Mohan offers personalized care to restore and enhance your smile. His expertise ensures precise and effective treatments, using the latest techniques to provide long-lasting results."
  },
  {
    name:'Dr .Shreya Rajesh - Periodontist',
    image:shreya,
    side:'right',
    content:"Dr. Shreya Rajesh is a highly skilled periodontist specializing in the prevention, diagnosis, and treatment of periodontal (gum) disease and in the placement of dental implants. With a focus on maintaining healthy gums and supporting structures of the teeth, Dr. Shreya is dedicated to providing comprehensive periodontal care, including scaling and root planing, gum surgeries, and regenerative procedures. She is committed to enhancing patients' oral health and overall well-being through personalized treatment plans tailored to their unique needs."
  },
  {
    name:'Ms. Asha - Receptionist / Dental Assistant',
    image:receptionist,
    side:'left',
    content:"Ms. Asha serves as the receptionist and dental assistant at Neosmile Dental & Implant Centre. With a warm and welcoming demeanor, she ensures that every patient feels comfortable from the moment they walk through the door. Her organizational skills and attention to detail help keep the clinic running smoothly, while her compassionate approach provides excellent support to both the dental team and patients."
  },

]

function About() {
  useEffect(()=>{
    window.scroll(0, 0);
  })
  return (
    <>
      <div className="container-fluid p-0">
        <div className="about-head">
          <h3>About us</h3>
          <div className="about-head-child">
            <img src={aboutHead} className="about-head-img"/>
          </div>
          <div className="about-child-2">

          </div>
        </div>
      </div>
      <div className="container">
        <div className="about-content">
          <h3 className="y-smile">Why Neo Smile?</h3>
          <p className="y-content">
            Neosmile Dental & Implant Centre offers specialized, individual
            solutions for each patient. Our dental office provides excellent
            quality care at reasonable prices. You can check our special offers
            by calling <span className="y-smile-contact">+91 7200943938</span>.
          </p>
          <p className="y-content">
            At Neosmile Dental & Implant Centre, we bring together the best
            professionals under one roof! Our professional staff is fully
            qualified, continuously attending the latest medical conferences to
            stay up to date on their skills and knowledge.
            <ul>
              <li>99% Satisfaction Rate</li>
              <li>750 patients treated per year</li>
              <li>8 qualified doctors</li>
              <li>Over 5 years of experience</li>
              <li>5000+ crowns and bridges delivered</li>
            </ul>
          </p>
        </div>
      </div>
      <div className="container w-75 m-auto">
        <div className="my-team-head">
          <h3>Meet Our Specialists</h3>
          <p className="my-team-content">
            Our friendly and highly qualified team is dedicated to providing you
            and your family with the best dental care at a reasonable price.
          </p>
        </div>
        <div className="row align-items-center">
          {
            doctorsData?.map((doc)=>{
              return (
                <>
                <div className={`row ${doc.side==='left'?"content-left":'content-right'}`}>
                <div className={`col-sm-12 col-md-6 col-lg-6 col-xl-6 mt-4 `}>
                    <div className="text-center">
                      <img src={doc.image} className="my-team-doc"/>
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6  mt-4">
                    <h3 className="dr-name">{doc.name}</h3>
                    <div className="text-start dr-content">{doc.content}</div>
                  </div>
                </div>
                  
                </>
              );
            })
          }
        </div>
      </div>
      <div className="container w-75 m-auto">
        <div className="dr-treat-head">
          <h3 className="">How we Treat</h3>
        </div>
        <div className="dr-treat-content">
          <p>
            We understand how a healthy and confident smile can transform your
            personal and professional life. At Neosmile Dental & Implant Centre,
            we go above and beyond to create a welcoming and supportive
            environment where your concerns and priorities are our top focus.
            Our goal is to develop treatment plans that are perfectly tailored
            to your individual needs, ensuring you receive the best possible
            care.
          </p>
          <p className="dr-treat-quote">
            “We are thrilled to welcome you to our Neosmile family and look
            forward to getting to know you. Whether you’re a new or returning
            patient, we are dedicated to providing exceptional care every step
            of the way.”
          </p>
        </div>
      </div>
      <div>
        <Testimonial />
      </div>
    </>
  );
}

export default About;
