import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";
import Navbar from "./Components/Pages/Navbar/Navbar";
import './../node_modules/bootstrap/dist/css/bootstrap.min.css'
import Floating from "./Components/Pages/floatingMenu/Floating";
import Home from "./Components/Pages/Home/Home";
import Footer from "./Components/Pages/Footer/Footer";
import About from "./Components/Pages/About/About";
import Aligners from "./Components/Pages/Treatments/Aligners/Aligners";
import Implants from "./Components/Pages/Treatments/Implants/Implants";
import Treatments from "./Components/Pages/Treatments/Treatments";
import Blog from "./Components/Pages/Blog/Blog";
import Contact from "./Components/Pages/Contact/Contact";
import whatsappLogo from './Components/Images/Icons/whatsapplogo.png'
import instagram from './Components/Images/Icons/instagram.png'
import call from './Components/Images/Icons/call.png'
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <BrowserRouter>
      <Navbar />
      <Floating />
      <div className="whatsapp-link">
        <a href="https://api.whatsapp.com/send?phone=7200943938&text=Hello%2C%20I%20Need%20a%20Appointment%20.%2C%20Please%20Contact%20me"
              target="_blank">
        <img src={whatsappLogo}/>
        </a>
        <div>
        <a href="https://www.instagram.com/neosmile_dental?igsh=bGtwOWNnZHRzN2R0"
              target="_blank">
        <img src={instagram}/>
        </a>
        </div>
        <div>
        <a href="tel:7200943938">
        <img src={call}/>
        </a>
        </div>
      </div>
      <Routes>
        <Route exact path="/" element={<Home/>}/>
        <Route exact path="/about" element={<About/>}/>
        {/* <Route exact path="/treatments/aligners" element={<Aligners/>}/> */}
        {/* <Route exact path="/treatments/dental-implants" element={<Implants/>}/> */}
        <Route exact path="/treatments/:id" element={<Treatments/>}/>
        <Route exact path="/blog" element={<Blog/>}/>
        <Route exact path="/contact" element={<Contact/>}/>
      </Routes>
      <Footer/>
      <Toaster
      position="bottom-centered"
      reverseOrder={false}
      gutter={8}
      containerClassName=""
      containerStyle={{}}
      toastOptions={{
        // Define default options
        className: "",
        duration: 5000,
        style: {
          background: "#363636",
          color: "#fff",
        },

        // Default options for specific types
        success: {
          duration: 3000,
          theme: {
            primary: "green",
            secondary: "black",
          },
        },
      }}/>
    </BrowserRouter>
  );
}

export default App;
